<template>
  <div>
    <div class="card card-custom mt-5">
      <div class="card-body">
        <!-- Filter -->
        <div>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row">
              <div class="col-12 mt-10">
                <table class="table table-bordered">

                  <tbody>
                  <tr>
                    <td v-if="$can('report_pos.total_product_sales_details')">
                      <span>{{ $t('MENU.total_pos_product_sales_details_report') }}</span>
                      <router-link :to="{name: 'total-pos-product-sales-details.list'}" target="_blank">
                        <span class="float-right ml-2">{{ $t('view') }}</span>
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="$can('report_pos.shifts_sales')">
                      <span>{{ $t('MENU.pos_shifts_sales_report') }}</span>
                      <router-link :to="{name: 'pos-shifts-sales-report.list'}" target="_blank">
                        <span class="float-right ml-2">{{ $t('view') }}</span>
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="$can('report_pos.total_categories_profits')">
                      <span>{{ $t('MENU.total_pos_categories_profits_report') }}</span>
                      <router-link :to="{name: 'total-pos-categories-profits-report.list'}" target="_blank">
                        <span class="float-right ml-2">{{ $t('view') }}</span>
                      </router-link>
                    </td>
                  </tr>
                  <tr>

                    <td v-if="$can('report_pos.detailed_pos_shifts_transactions')">
                      <span>{{ $t('MENU.detailed_pos_shifts_transactions_report') }}</span>
                      <router-link :to="{name: 'detailed-pos-shifts-transactions-report.list'}" target="_blank">
                        <span class="float-right ml-2">{{ $t('view') }}</span>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.pos_reports")}]);
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    isSystemUser: function () {
      return this.currentUserPersonalInfo.is_system_user
    },
  },
}
</script>
<style scoped>
td {
  padding: 1rem;
}
</style>